.container {
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  width: 44px;
  height: 24px;

  input[type='checkbox'] {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: var(--color-blue);
      border: 1px solid var(--color-blue);

      &.disabled {
        background-color: var(--color-gray-200);
      }
    }

    &:checked + .slider:before {
      transform: translateX(20px);
    }
  }

  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-gray-850);
    border-color: var(--color-gray-700);
    border: 1px solid;
    border-radius: 22px;
    transition: 0.2s;
    cursor: pointer;

    &:before {
      position: absolute;
      content: '';
      height: 18px;
      width: 18px;
      left: 3px;
      bottom: 2px;
      background-color: var(--color-white);
      border-radius: 50%;
      transition: 0.2s;
    }
  }
}
