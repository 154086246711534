.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 32px;

  @media (max-width: 767px) {
    padding: 24px 16px;
  }

  &:hover {
    .expandIcon {
      opacity: 1;
    }
  }

  .expandIcon {
    opacity: 0;
    transition: all ease 0.5s;

    @media (max-width: 767px) {
      opacity: 1;
    }

    &.open {
      transform: rotate(180deg);
    }
  }
}

.collapse {
  transition: height 500ms;

  .content {
    height: 100%;
    padding: 0 32px;

    @media (max-width: 767px) {
      padding: 0 16px;
    }
  }
}
