$sm-width: 428px;
$md-width: 768px;
$lg-width: 1024px;
$xl-width: 1440px;

@mixin sm {
  @media (min-width: #{$sm-width}) {
    @content;
  }
}

@mixin md {
    @media (min-width: #{$md-width}) {
      @content;
    }
  }

@mixin lg {
  @media (min-width: #{$lg-width}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$xl-width}) {
    @content;
  }
}