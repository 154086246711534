.border-rainbow {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    inset: -1px;
    border-radius: inherit;
    border-radius: inherit;
    padding: 1px;
    @apply bg-rainbow;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}
