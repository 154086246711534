.item {
  @apply relative px-1 border;

  &:first-child {
    @apply rounded-l-full;
  }
  &:last-child {
    @apply rounded-r-full;
  }
}
