.modalContainer {
  overflow: hidden;
  border: none;
}

.container {
  margin: -25px;
  z-index: 5;

  @media (max-width: 767px) {
    margin: 0;
  }

  .hero {
    max-width: 100%;
  }

  .content {
    padding: 24px 32px 32px;

    .title {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
    }

    .description {
      margin-top: 16px;
      font-size: 16px;
      line-height: 24px;
      @apply text-gray-300;
    }
  }
}
