.solanaGradient {
  border-bottom: 1px solid transparent;

  /* Counteracts the solana border */
  &:after {
    padding-bottom: 0;
    @screen md {
      padding-bottom: 1px;
    }
  }

  /* Gradient Blur */
  &:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    z-index: -1;
    @apply bg-solana--circle;
    filter: blur(34px);
  }

  /* Extra settings due to background gradient */
  > header {
    background-color: var(--bgColor);
  }

  > main {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
