.container {
  position: relative;

  .validation {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #2bd859;

    &.loading {
      width: 20px;
      height: 20px;
    }

    &.invalid {
      color: #ff3636;
    }

    .validationIcon {
      margin-left: 8px;
    }
  }

  .favorites {
    position: absolute;
    top: 54px;
    left: 0;
    right: 0;
    padding: 16px;
    margin-top: 8px;
    box-shadow: 0px 2px 27px rgba(0, 0, 0, 0.5);
    z-index: 1;
    @apply bg-gray-800 border border-gray-700 rounded-2xl;
  }
}
