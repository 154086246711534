.container {
  min-height: var(--height);
  display: flex;
  flex-direction: column;
  overflow: auto;

  .content {
    flex: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 448px;
    padding: 0 24px;
    margin: 0 auto;

    .logo {
      margin: 40px auto;

      svg {
        width: 72px;
        height: 64px;
      }
    }
  }
}
