.transferDetail {
  width: 437px;
  padding-top: 44px;
  @media (max-width: 767px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &.alignCenter {
    text-align: center;
  }

  .iconSuccess {
    display: inline-block;
    margin: 24px auto 0;
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 18px;
    margin-bottom: 2px;
    @apply text-gray-300;
  }

  .textareaContainer {
    resize: vertical;
    box-shadow: 0px 1.2963px 4.09954px rgba(0, 0, 0, 0.0227778),
      0px 0.77037px 2.22963px rgba(0, 0, 0, 0.0182222),
      0px 0.4px 1.1375px rgba(0, 0, 0, 0.015),
      0px 0.162963px 0.57037px rgba(0, 0, 0, 0.0117778),
      0px 0.037037px 0.275463px rgba(0, 0, 0, 0.00722222);
    box-sizing: border-box;
    border-radius: 10px;
    line-height: 0;
    width: 100%;
    max-width: 100%;
    margin-top: 12px;
    padding: 8px;
    @apply border border-gray-100;

    textarea {
      border: none;
      font-size: 16px;
      line-height: 19px;
      min-width: 100%;
      max-width: 100%;
      min-height: 79px;
      margin: 0;
      padding: 8px;
    }
  }

  .amountContainer {
    margin-top: 24px;
    padding-top: 24px;
    @apply border-t border-gray-50;

    @media (max-width: 767px) {
      flex: 1;
    }

    .amountInputLabel {
      margin-top: 16px;
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }

    .amountInputContainer {
      box-shadow: 0px 1.2963px 4.09954px rgba(0, 0, 0, 0.0227778),
        0px 0.77037px 2.22963px rgba(0, 0, 0, 0.0182222),
        0px 0.4px 1.1375px rgba(0, 0, 0, 0.015),
        0px 0.162963px 0.57037px rgba(0, 0, 0, 0.0117778),
        0px 0.037037px 0.275463px rgba(0, 0, 0, 0.00722222);
      border-radius: 10px;
      height: 54px;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      @apply border border-gray-100;

      input {
        margin-right: 10px;
        flex: 1;
        border: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
      }
      button {
        border-radius: 6px;
        padding: 4px 8px;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
        line-height: 140%;
        @apply text-gray-25 bg-blue border border-blue;

        &:disabled {
          @apply bg-gray-50 border border-gray-50 text-gray-300;
        }
      }
    }
  }

  .confirmInfoContainer {
    margin: 16px 0;
    @media (max-width: 767px) {
      flex: 1;
    }
  }

  .balanceContainer {
    border-radius: 8px;
    padding: 20px;
    display: flex;
    align-items: center;
    @apply bg-gray-25;

    > div {
      flex: 1;
      &:first-child {
        padding-right: 32px;
      }
      &:last-child {
        padding-left: 32px;
        @apply border-l border-gray-100;
      }

      .label {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        @apply text-gray-300;
      }
      .value {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;

        margin-top: 12px;
      }
    }
  }

  .confirmNote {
    margin-top: 24px;
    font-size: 16px;
    line-height: 24px;
    @apply text-gray-300;

    b {
      color: black;
    }
  }

  .btnDetail {
    margin-top: 24px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    @apply text-blue;
  }
}

.verify2FA {
  width: 422px;
  margin-top: 24px;
  text-align: center;
  z-index: 5;

  @media (max-width: 768px) {
    padding: 16px;
  }

  .iconInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 72px;
    margin: auto;
    border-radius: 21px;
    @apply bg-gray-50;
  }

  .title {
    margin-top: 24px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    @media (max-width: 768px) {
      position: absolute;
      top: 16px;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 0;
      font-size: 16px;
    }
  }

  .description {
    max-width: 400px;
    margin-top: 8px;
    font-size: 14px;
    line-height: 18px;
    @apply text-gray-300;
  }

  .seperator {
    height: 1px;
    margin: 24px 0;
    @apply bg-gray-100;
  }

  .footer {
    display: flex;
    margin-top: 24px;

    button:not(:first-child) {
      margin-top: 0;
      margin-left: 24px;
    }
  }
}
