.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 104px 0;

  .title {
    font-weight: 700;
    font-size: 36px;
    line-height: 38px;
    text-align: center;
    margin: 0;
    @apply text-white;
  }

  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 24px;
    max-width: 580px;
    @apply text-white;
  }

  .btn {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    height: 56px;
    cursor: pointer;
    text-decoration: none;

    background: linear-gradient(
      269.11deg,
      #ffda4a -3.22%,
      #ff00db 55.46%,
      #2577f1 114.05%
    );
    box-shadow: 0px 1.2963px 4.09954px rgba(0, 0, 0, 0.0227778),
      0px 0.77037px 2.22963px rgba(0, 0, 0, 0.0182222),
      0px 0.4px 1.1375px rgba(0, 0, 0, 0.015),
      0px 0.162963px 0.57037px rgba(0, 0, 0, 0.0117778),
      0px 0.037037px 0.275463px rgba(0, 0, 0, 0.00722222);
    border-radius: 80px;
    @apply text-white;
  }
}
