.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 750;
}

.modal {
  position: absolute;
  width: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px;
  border-radius: 12px;
  outline: none;
  @apply bg-white;

  @media (max-width: 767px) {
    height: 100%;
    width: 100%;
    border-radius: 0;
  }

  .container {
    display: flex;
    @media (max-width: 767px) {
      height: 100%;
    }

    .btnClose {
      display: block;
      position: absolute;
      top: 24px;
      left: 24px;
      @apply text-gray-300;
    }

    .btnBack {
      display: flex;
      align-items: center;
      position: absolute;
      top: 24px;
      right: 32px;
      font-size: 14px;
      @apply text-gray-300;
    }
  }
}
