.videoContainer {
  opacity: 0;
  pointer-events: none;
  position: absolute !important;
  inset: 0;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(.isFullScreen) {
    video {
      width: calc(var(--width) * 0.7);
      height: calc(var(--height) * 0.7);
    
      @media (max-width: 767px) {
        width: calc(var(--width) * 0.9);
        height: calc(var(--height) * 0.8);
      }
    }
  }

  &.isFullScreen { // remove extra class when all videos at 16x9 https://app.asana.com/0/1201257980051440/1202808619064467
    flex: 1;
    width: 100%;
    position: relative;
    
    video {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.btnPlay {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 96px;
  width: 96px;
  border: solid 2px white;
  background-color: rgba(0,0,0,0.3);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,1);
  color: white;
  border-radius: 48px;
  font-size: 16px;
  outline: none;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  transform-origin: center center;
  transform: translate(-50%, -50%) scale(1,1);
  
  &:hover {
    transform: translate(-50%, -50%) scale(1.2,1.2);
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,1);
  }
}
