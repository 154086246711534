.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 750;
}

.modal {
  position: absolute;
  width: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  height: 100%;
  width: 100%;
  border-radius: 0;
  @apply bg-white;

  .header {
    position: relative;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    font-weight: bold;
    font-size: 14px;
    @apply border-b border-gray-50;

    .btnBack {
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      font-size: 16px;
    }
  }

  .container {
    display: flex;
    height: calc(100% - 64px);
    padding: 24px 16px;
    position: relative;
    overflow: auto;
  }
}
