.errorBox {
  max-width: 445px;
  margin-bottom: 32px;
  border-radius: 88px;

  @media (max-width: 1024px) {
    margin: 0 auto 32px;
  }
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 445px;
  padding: 7px 7px 7px 24px;
  background: linear-gradient(
    133.14deg,
    rgba(255, 255, 255, 0.16) 5.55%,
    rgba(0, 0, 0, 0) 113.4%
  );
  box-sizing: border-box;
  filter: drop-shadow(0px 1.2963px 4.09954px rgba(0, 0, 0, 0.0227778))
    drop-shadow(0px 0.77037px 2.22963px rgba(0, 0, 0, 0.0182222))
    drop-shadow(0px 0.4px 1.1375px rgba(0, 0, 0, 0.015))
    drop-shadow(0px 0.162963px 0.57037px rgba(0, 0, 0, 0.0117778))
    drop-shadow(0px 0.037037px 0.275463px rgba(0, 0, 0, 0.00722222));
  border-radius: 100px;
  @apply border border-gray-100;

  @media (max-width: 1024px) {
    margin: auto;
  }

  @media (max-width: 768px) {
    display: block;
    padding: 0;
    background: transparent;
    border: none;
    box-sizing: border-box;
    filter: none;
    border-radius: 0;
  }

  .input {
    flex: 1;
    font-size: 16px;
    line-height: 19px;
    background-color: transparent;
    border: none;
    outline: none;
    @apply text-white;

    @media (max-width: 768px) {
      width: 100%;
      padding: 16px;
      text-align: center;
      background: linear-gradient(
        133.14deg,
        rgba(255, 255, 255, 0.16) 5.55%,
        rgba(0, 0, 0, 0) 113.4%
      );
      box-sizing: border-box;
      filter: drop-shadow(0px 1.2963px 4.09954px rgba(0, 0, 0, 0.0227778))
        drop-shadow(0px 0.77037px 2.22963px rgba(0, 0, 0, 0.0182222))
        drop-shadow(0px 0.4px 1.1375px rgba(0, 0, 0, 0.015))
        drop-shadow(0px 0.162963px 0.57037px rgba(0, 0, 0, 0.0117778))
        drop-shadow(0px 0.037037px 0.275463px rgba(0, 0, 0, 0.00722222));
      border-radius: 100px;
      @apply border border-gray-100;
    }
  }

  .btnContinue {
    width: 132px;
    height: 40px;
    margin: 0;
    border-radius: 80px;

    @media (max-width: 768px) {
      width: 100%;
      height: 54px;
      margin-top: 16px;
      border-radius: 80px;
    }
  }
}
