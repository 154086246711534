.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  @apply bg-modalOverlay z-modal;
}

.wrapper {
  position: fixed;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-height: calc(var(--height) - 32px);
  bottom: 0;
  border-radius: 12px 12px 0px 0px;
  overflow-y: auto;
  overflow-x: hidden;
  transform: translateY(100%);
  @apply bg-gray-850 z-modal;

  &:global(.appear-active),
  &:global(.appear-done),
  &:global(.enter-active),
  &:global(.enter-done) {
    transform: none;
    transition: transform 500ms cubic-bezier(0.25, 1, 0.5, 1);
  }

  &:global(.exit) {
    transform: none;
  }

  &:global(.exit-active) {
    transform: translateY(100%);
    transition: transform 500ms cubic-bezier(0.25, 1, 0.5, 1);
  }
}
