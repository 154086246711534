.modal {
  position: absolute;
  width: 460px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px;
  @apply bg-gray-850;

  box-sizing: border-box;
  box-shadow: 0px 4.62222px 13.6963px rgba(10, 11, 9, 0.0364444),
    0px 2.4px 6.9875px rgba(10, 11, 9, 0.03),
    0px 0.977778px 3.5037px rgba(10, 11, 9, 0.0235556),
    0px 0.222222px 1.69213px rgba(10, 11, 9, 0.0144444);
  border-radius: 12px;
  outline: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 750;
  @apply bg-modalOverlay;
}
