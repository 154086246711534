@import '../../styles/animation.scss';

.thumbnails {
  position: relative;

  .thumbnail {
    position: relative;
    margin: 0 auto;
    max-width: 100%;
    object-fit: contain;
    cursor: pointer;

    &.round {
      border-radius: 45px;
    }
  }

  .thumbnailLoader {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 4px;
    overflow: hidden;
    @apply bg-gray-50;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
      content: '';
    }
  }
}

.previews {
  display: flex;
  justify-content: center;

  .preview {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    cursor: pointer;
    border: 1px solid transparent;

    &.active {
      box-sizing: border-box;
      border-radius: 5px;
      @apply border-gray-50;
    }
  }
}
