.turnstile {
  position: relative;
  overflow: hidden;

  div {
    div {
      display: flex;
      justify-content: center;
    }
  }
}
