.container {
  > div {
    display: flex;
  }
}


.svgContainer {
  display: flex;
}
