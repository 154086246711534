.container {
  position: relative;
  @apply rounded-full box-content;

  .spinner {
    position: relative;
    animation: rotation 2s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
