@import '../../styles/animation.scss';

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 72px;
  height: 72px;
  animation: center-rotator .8s linear infinite;

  &.large {
    width: 56px;
    height: 56px;
  }

  &.medium {
    width: 32px;
    height: 32px;
  }

  &.small {
    width: 20px;
    height: 20px;
  }

  &.tiny {
    width: 14px;
    height: 14px;
  }

  circle {
    fill: none;
    stroke-width: 10px;
    stroke-dashoffset: 60px;
    stroke-dasharray: 180px;
    stroke-linecap: round;
    transform-origin: center;
  }
}
