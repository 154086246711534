.container {
  z-index: 5;

  @media (max-width: 768px) {
    padding: 24px;
  }

  .title {
    margin-top: 24px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }

  .description {
    margin-top: 16px;
    font-size: 16px;
    line-height: 24px;
    @apply text-gray-300;
  }
}
